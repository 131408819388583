import React from "react"

import Layout from '../components/Layout';
import Seo from '../components/seo';

import * as S from "../components/StylePrivacyPolicy/styled";

const PrivacyPolicyPage = () => {
  return (
    <Layout title="Quem somos">
      <Seo title="Quem somos" />
      <S.CourseAboutWrapper>
        <S.CourseAboutInfos>
          <div>
            <h1>Política de privacidade da Devload</h1>
            <p>
              Conforme as normas previstas na Lei 13.709/18, que versa sobre a proteção de dados e as demais legislações pertinentes, apresentamos
              a Política de Privacidade e Segurança do Devload.<br /><br />
              Este documento é vinculado e integra as Condições e Termos de Uso deste site, sendo passível de alteração a qualquer momento para 
              adequação em regras estabelecidas pelas legislações que estabelecem o modo de tratamento dos dados.<br /><br />
              Caso tenha alguma dúvida entre em contato conosco, pela central de atendimento pelo e-mail ola.devload@gmail.com
            </p>
            <h2>1 - Da coleta de dados:</h2>
            <p>
            Realizamos a coleta de dados dos usuários/visitantes/clientes do nosso site não apenas para realização dos serviços que prestamos, como 
            também por segurança, já que qualquer ato irregular ou ilícito praticado por ele poderá ser passível de consequências especificas para o responsável.<br/><br/>
            A utilização de determinadas funções do nosso site depende expressamente da coleta de dados dos usuários como: nome, telefone de contato, 
            e-mail, bem como os demais dados cabíveis para a finalidade que se apresenta no site.<br /><br />
            A Coleta de Dados pode acontecer por todas as áreas do nosso site/blog, uma vez que ela acontece por meio do 
            preenchimento de formulários, FAQ, envio de dúvidas, Chat ou cadastro.<br /><br />
            Cientificamos ainda, que ao preencher formulário/FAQ/Cadastro ou qualquer tipo de área referente ao retorno de contato ou 
            duvidas em nosso site, assim como o ACEITE dos COOKIES que utilizamos, O USUÁRIO /CLIENTE demonstra A CONCORDÂNCIA de 
            forma objetiva, clara e expressa da coleta e tratamento de seus dados que será realizada pelo site/equipe do site/ parceiros 
            do site respeitando todas as normas de proteção aos dados pessoais.<br /><br />
            É indispensável ainda, informarmos que trabalhamos com ferramenta de análise de dados (analytics ou similar) para monitorar 
            os resultados de acessos, comentários, entre outras informações sobre o desempenho do nosso site/blog. 
            </p>
            <h2>2 - Sobre a nossa Equipe</h2>
            <p>
            Nossa equipe tem ciência de todos os mecanismos técnicos e administrativos que devem ser utilizados no tratamento 
            de dados, pois informamos em nossa Política Interna, conhecida por todos os nossos colaboradores o modo como deve 
            acontecer todas as etapas do tratamento de dados, respeitando a privacidade e todas as normas trazidas na Lei 13.709/18.<br /><br />
            OBS: Toda equipe que trabalha conosco tem conhecimento de todas as práticas e técnicas que devem ser utilizadas no 
            tratamento dos dados que coletamos, pois todos tiveram e continuam a ter acesso a nossa Política Interna e Política
             de Governança, bem como possuem treinamentos específicos para isso, sendo que qualquer ato (realizado por alguém que 
             participe da nossa equipe) considerado ilícito ou danoso ao titular de dados gerara responsabilização direta e será 
             motivo cabível para demissão com justa causa. 
            </p>
            <h2>3 - Cookies e Newlestter</h2>
            <p>
            Os cookies são arquivos que possuem tamanhos pequenos e são enviados pelo nosso site ao seu computador, para 
            que assim possamos armazenar informações de navegação e os dados do usuário/cliente necessários 
            para o serviço que desempenhamos.<br /><br />
            Os cookies são o meio pelo qual realizamos o registro de dados e preferenciais dos nossos usuários/clientes 
            de modo rápido para que possamos proporcionar uma experiência adequada ao ser realizado o acesso a nossa 
            página, trazendo inclusive serviços relacionados as pesquisas que você faz em nosso site/blog.<br /><br />
            Informamos ainda, que nem todos os cookies trazidos neste site/blog realiza a coleta e o armazenamento de 
            dados pessoais, visto que alguns dos cookies trazidos são utilizados para o desempenho de 
            algum dos serviços que prestamos.<br /><br />
            A “newlestter” é o meio pelo qual notificamos e informamos os nossos usuários/clientes de qualquer 
            atualização que realizamos.<br /><br />
            Os dados informados para a “newlestter” são utilizados apenas com essa finalidade, sem 
            qualquer destinação contrária ou que desrespeite a legislação de Proteção de Dados.<br /><br />
            Você não é obrigado a aceitar os “cookies” ou a “newlestter”, é de sua livre escolha optar ou não pelo registro 
            de “cookies”, caso não deseje que ele aconteça, utilize a opção em 
            seu navegador/ aparelho de desativação do registro de cookies.<br /><br />
            Informamos que a desativação dos cookies pode lhe trazer uma experiência incompleta ou com falhas na sua visita
             ao nosso site/blog, além de não trazer o desempenho correto das ferramentas e funções que disponibilizamos em nossas páginas. 
            </p>
            <h2>4 - Consentimento do usuário</h2>
            <p>
            O usuário/cliente ao acessar o nosso site/blog demonstra está de acordo com todas as informações contidas em 
            nossa Política de Privacidade e Termos de Uso, bem como concede de livre e espontânea vontade que 
            seus dados sejam tratados pela nossa equipe respeitando os princípios da finalidade, necessidade e adequação.<br /><br />
            É livre o consentimento do usuário/cliente, uma vez que não utilizamos de nenhum meio de coação, obrigatoriedade, indução, 
            constrangimento, ameaça ou imposição para que o usuário/cliente conceda e permita que seja
             realizado o tratamento de seus dados. 
            </p>
          </div>
        </S.CourseAboutInfos>
      </S.CourseAboutWrapper>
    </Layout>
  );
};

export default PrivacyPolicyPage;